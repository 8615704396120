// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/Users/jonathanstuetz/Workspace/Gatsby/JohnTheHuman/portfolio/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("/Users/jonathanstuetz/Workspace/Gatsby/JohnTheHuman/portfolio/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-imprint-js": () => import("/Users/jonathanstuetz/Workspace/Gatsby/JohnTheHuman/portfolio/src/pages/Imprint.js" /* webpackChunkName: "component---src-pages-imprint-js" */),
  "component---src-pages-index-js": () => import("/Users/jonathanstuetz/Workspace/Gatsby/JohnTheHuman/portfolio/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("/Users/jonathanstuetz/Workspace/Gatsby/JohnTheHuman/portfolio/src/pages/Privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-projects-brotladen-js": () => import("/Users/jonathanstuetz/Workspace/Gatsby/JohnTheHuman/portfolio/src/pages/projects/Brotladen.js" /* webpackChunkName: "component---src-pages-projects-brotladen-js" */),
  "component---src-pages-projects-design-thinking-out-of-the-box-js": () => import("/Users/jonathanstuetz/Workspace/Gatsby/JohnTheHuman/portfolio/src/pages/projects/DesignThinkingOutOfTheBox.js" /* webpackChunkName: "component---src-pages-projects-design-thinking-out-of-the-box-js" */),
  "component---src-pages-projects-distant-grounds-js": () => import("/Users/jonathanstuetz/Workspace/Gatsby/JohnTheHuman/portfolio/src/pages/projects/DistantGrounds.js" /* webpackChunkName: "component---src-pages-projects-distant-grounds-js" */),
  "component---src-pages-projects-hellonaut-js": () => import("/Users/jonathanstuetz/Workspace/Gatsby/JohnTheHuman/portfolio/src/pages/projects/Hellonaut.js" /* webpackChunkName: "component---src-pages-projects-hellonaut-js" */),
  "component---src-pages-projects-productivity-timer-js": () => import("/Users/jonathanstuetz/Workspace/Gatsby/JohnTheHuman/portfolio/src/pages/projects/ProductivityTimer.js" /* webpackChunkName: "component---src-pages-projects-productivity-timer-js" */),
  "component---src-pages-projects-time-machine-js": () => import("/Users/jonathanstuetz/Workspace/Gatsby/JohnTheHuman/portfolio/src/pages/projects/TimeMachine.js" /* webpackChunkName: "component---src-pages-projects-time-machine-js" */)
}

