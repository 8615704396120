module.exports = [{
      plugin: require('/Users/jonathanstuetz/Workspace/Gatsby/JohnTheHuman/portfolio/node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Barlow Condensed","Open Sans"]}},
    },{
      plugin: require('/Users/jonathanstuetz/Workspace/Gatsby/JohnTheHuman/portfolio/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/jonathanstuetz/Workspace/Gatsby/JohnTheHuman/portfolio/node_modules/gatsby-plugin-page-progress/gatsby-browser.js'),
      options: {"plugins":[],"includePaths":[{"regex":"^/projects"}],"height":10,"prependToBody":false,"color":"#3ECCC7"},
    }]
